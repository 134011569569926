<template>
  <div class="tiku-report">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">练习报告</div>
      <div class="right"></div>
    </div>
    <div class="brief-info">
      <div class="title">
        考试情况
      </div>
      <div class="grade">
        <div class="item">
          <div class="head">
            一共
          </div>
          <div class="body">
            15题
          </div>
        </div>
        <div class="item">
          <div class="head">
            答对
          </div>
          <div class="body green">
            10题
          </div>
        </div>
        <div class="item">
          <div class="head">
            得分
          </div>
          <div class="body">
            10分
          </div>
        </div>
        <div class="item">
          <div class="head">
            用时
          </div>
          <div class="body">
            20分15秒
          </div>
        </div>
      </div>
    </div>
    <div class="detail-box">
      <div class="head">
        答题卡
      </div>
      <div class="box">
        <div class="chapter">
          <div class="title">
            单选题
          </div>
          <div class="item-box">
            <div class="q green" v-for="(item, index) in testData" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
        <div class="chapter">
          <div class="title">
            单选题
          </div>
          <div class="item-box">
            <div class="q red" v-for="(item, index) in testData" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <button class="btn">查看解析</button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      testData: [],
    };
  },
  methods: {
    onGoBack() {
      this.$router.go(-1);
    },
    onGotoAppDownload(mobileType) {
      this.$router.push({
        name: "addToScreenStep",
        query: {
          type: mobileType,
        },
      });
    },
  },
  mounted() {
    for (let i = 0; i < 130; i++) {
      this.testData.push(i);
    }
    // console.log(this.testData);
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tiku-report {
  display: flex;
  flex-direction: column;
  background: rgb(241, 241, 241);
  height: 100%;
  align-items: center;
  .header {
    height: 62px;
    width: 100%;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 18px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .brief-info {
    height: 112px;
    width: 344px;
    margin: 14px 0px 0px 0px;
    border-radius: 8px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    .title {
      margin: 2px 16px;
      text-align: left;
      font-size: 16px;
      color: #242424;
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 500;
    }
    .grade {
      width: 315px;
      height: 58px;
      margin: 0px 15px 12px 15px;
      background: #f6f7fb;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .head {
          margin: 8px 0px 2px 0px;
          font-size: 14px;
          color: #9298a4;
          letter-spacing: 0.31px;
          font-weight: 500;
        }
        .body {
          font-weight: 500;
          font-size: 12px;
          color: #4d52d1;
          letter-spacing: 0.27px;
        }
        .green {
          color: #24c89f;
        }
      }
    }
  }
  .detail-box {
    width: 344px;
    margin: 12px 0px 0px 0px;
    background: #ffffff;
    border-radius: 8px;
    overflow-y: scroll;
    flex: 1 1 auto;
    .head {
      font-size: 16px;
      color: #242424;
      letter-spacing: 0;
      text-align: left;
      line-height: 30px;
      font-weight: 500;
      margin: 10px 24px;
    }
    .box {
      .chapter {
        margin: 10px 24px;
        .title {
          font-size: 16px;
          color: #323232;
          letter-spacing: 0.36px;
        }
        .item-box {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .q {
            width: 32px;
            height: 32px;
            margin: 10px 12px 10px 0px;
            border-radius: 16px;
            color: #ffffff;
            font-size: 16px;
            color: #ffffff;
            letter-spacing: 0.36px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            // &:first-child {
            //   margin-left: 0px;
            // }
          }
          .green {
            background: #24c89f;
          }
          .red {
            background: #ff6e67;
          }
        }
      }
    }
  }
  .footer {
    flex: 0 0 auto;
    margin: 12px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
      width: 344px;
      height: 40px;
      background: #4d52d1;
      border-radius: 12px;
      font-weight: 500px;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0.36px;
      border: none;
    }
  }
}
</style>
